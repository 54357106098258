import React from "react";

function SkillsBar({ skill, value, isScrolled }) {
  return (
    <div style={{ width: "95%" }}>
      <p className="lead mb-1 mt-2 font-weight-bold">
          <center><b>{skill}</b></center>
      </p>
    </div>
  );
}

export default SkillsBar;
