// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Sepehr",
  middleName: "",
  lastName: "Ganji",
  message: " Passionate about learning new things. ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/SepehrGanji",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/SepehrGanji/",
    },
    {
      image: "fa-telegram",
      url: "https://t.me/SepehrGanji",
    },
  ],
};

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/SepehrGanji.png"),
  imageSize: 375,
  message: "My name is Sepehr Ganji. I am an M.Sc. student in Computer Science at the University of Calgary. My main interests are networking, operating systems, and cloud computing. I love to develop fault-tolerant software systems and work with modern DevOps technologies.",
  resume: "https://drive.google.com/file/d/1uWD8vy1tnbFq_FCSuPs3E_AVJm0mETK-/view?usp=sharing",
};

const repos = {
  show: true,
  heading: "Public Projects",
  gitHubUsername: "sepehrganji",
  reposLength: 6,
  specificRepos: ["capt-nemo429/ergo-graphql", "sepehrganji/voter-backend", "SepehrGanji/Secure-Banking-System", "SepehrGanji/CLRS", "SepehrGanji/Mini_NMap", "SepehrGanji/Mini_Shark"],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    { 
      img: require("../editable-stuff/SepehrGanji.png"),
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/SepehrGanji.png"),
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "JavaScript", value: 80 },
    { name: "TypeScript", value: 60 },
    { name: "Docker", value: 80 },
    { name: "Kubernetes", value: 30 },
    { name: "C", value: 40 },
    { name: "C++", value: 40 },
    { name: "Python", value: 40 },
    { name: "Linux", value: 60 },
    { name: "Node.js", value: 80 },
    { name: "Graphql", value: 60 },
    { name: "BlockChain", value: 80 },
    { name: "SmartContracts", value: 60 },
  ],
  softSkills: [
    { name: "Leading", value: 80 },
    { name: "Adaptability", value: 60 },
    { name: "Problem Solving", value: 60 },
    { name: "Creativity", value: 80 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: false,
  heading: "Get In Touch",
  message:
    "",
  email: "",
};

const experiences = {
  show: true,
  heading: "Experiences",
  data: [
    {
      company: 'Ergo Platform',
      role: 'Blockchain Developer',
      companylogo: require('../assets/img/ergo.png'),
      date: 'May 2022 – Jun 2023',
    },
    {
      company: 'NDPA',
      role: 'Software & DevOps Engineer',
      companylogo: require('../assets/img/ndpa.png'),
      date: 'May 2021 – Jun 2022',
    },
    {
      company: 'CESSA',
      role: 'Chairperson',
      companylogo: require('../assets/img/cessa.png'),
      date: 'Sep 2020 – Sep 2021',
    },
    {
      company: 'AICup',
      role: 'Technical Team Lead',
      companylogo: require('../assets/img/aicup.png'),
      date: 'Apr 2021 – Sep 2021',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: true,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences };
